import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom';
import DashboardNav from '../components/DashboardNav'
import { formatAsCurrency, formatHealthCheckAsJob } from '../utils';
import recTypes from '../json/recommendation_types.json';
import { calculateSavings, calculateSof, calculateOpen, calculateNos, sofTotal, openTotal, nosTotal, calculateSpycareAccepted, spycareAcceptedTotal } from '../utils/calculate'
import Table from 'react-bootstrap/Table'
import { useJob } from '../components/JobProvider'
import { usePortal } from '../components/PortalProvider';
import { Redirect, useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import styles from './RecommendationSummary.module.scss';
import { useAuth } from '../components/AuthProvider';

const HealthCheckSummary = ({ setPortal }) => {
  const { spycareJobId: spycareJob } = useJob()
  const history = useHistory()
  const portal = usePortal()
  const { healthCheckId } = useParams()
  const [job, setJob] = useState({ forwardTerms: 12 })
  const [eliminations, setEliminations] = useState([])
  const [reductions, setReductions] = useState([])
  const [others, setOthers] = useState([])
  const [location, setLocation] = useState('All Locations')
  const [locations, setLocations] = useState([])
  const [savings, setSavings] = useState(null)
  const [savingsOpps, setSavingsOpps] = useState(null)
  const { token } = useAuth()

  useEffect(() => {
    if (spycareJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/health_check/spycare_job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(healthChecks => {
          let healthCheck = { recommendations: [] }
          if (healthChecks.length) {
            if (healthCheckId) {
              healthCheck = healthChecks.find(hc => hc.healthCheckId == healthCheckId)
            } else {
              healthCheck = healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)[0]
            }
          }

          const recs = healthCheck.recommendations || []

          const recLocations = new Set()
          recs.forEach((rec) => {
            if (rec.inventory?.address && rec.inventory?.city && rec.inventory?.state) {
              recLocations.add(`${rec.inventory.address}, ${rec.inventory.city}, ${rec.inventory.state}`)
            }
          })

          const healthCheckAsJob = formatHealthCheckAsJob(healthCheck)
          const { savingsCaptured, openRecCount } = calculateSavings(healthCheckAsJob, 1)
          setJob(healthCheckAsJob)
          setLocations(['All Locations', ...recLocations])
          setEliminations(healthCheckAsJob.recSummaries.filter((sum => sum.recommendationType.category === 'Eliminate')))
          setReductions(healthCheckAsJob.recSummaries.filter((sum => sum.recommendationType.category === 'Cost Reduction')))
          setOthers(healthCheckAsJob.recSummaries.filter((sum => !['Eliminate', 'Cost Reduction'].includes(sum.recommendationType.category))))
          setSavings(savingsCaptured)
          setSavingsOpps(openRecCount)
        })
    }
  }, [spycareJob, healthCheckId])

  return (
    <>
      <DashboardNav />
      < Container className="mt-3" >
        <Row>
          <Col xs={12} className="d-flex justify-content-between align-content-center">
            <h1 className="mb-0">HealthCheck Summary</h1>
            <div className="text-end">
              <Button variant="outline-blueGreyDark" onClick={() => { history.push(`/inventory/health-check/${job.healthCheckId}`) }}>
                View Inventory
              </Button>
            </div>
          </Col>
        </Row>
      </Container >
      <Container className="mt-3">
        <Row className="">
          <Col xs={12} lg={12}>
            <Card className={`${styles.responsiveMt3__md}`}>
              <Card.Header className="pb-0">
                <Row>
                  <div className="cardTitleContainer align-items-center d-block d-md-flex">
                    <Card.Title>Savings</Card.Title>
                    <div className=" d-none d-md-block p-3"></div>
                    <div className="statsContainerColumn justify-content-start p-3">
                      <div>
                        <Card.Title className="stats text-center">{formatAsCurrency(savings)}</Card.Title>
                        <Card.Text className="stats-descripton text-center">in Annual Savings</Card.Text>
                      </div>
                      <div>
                        <Card.Title className="stats text-center">{savingsOpps}</Card.Title>
                        <Card.Text className="stats-descripton text-center">Savings Opportunities Available</Card.Text>
                      </div>
                    </div>
                  </div>
                </Row>
              </Card.Header>
              <div className={styles.table}>
                <div className={styles.tableHeader}>
                  <div className={styles.tableFilter}>
                    <label>Filter by Location:</label>
                    <Form.Select className="custom-select" id="inputGroupSelect" value={location} onChange={(e) => setLocation(e.target.value)}>
                      {locations.map((loc) => (
                        <option key={loc}>{loc}</option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <Table bordered>
                  <tr>
                    <th><h6 className="mb-0">Recommendation Category</h6></th>
                    <th className={`${styles.currency} ${styles.columnLabel}`}><h6 className="mb-0">Savings Potential</h6></th>
                    <th className={`${styles.currency} ${styles.columnLabel}`}><h6 className="mb-0"><span className="color-warning-alt">Open / Outstanding</span></h6></th>
                    <th className={`${styles.currency} ${styles.columnLabel}`}><h6 className="mb-0"><span className="color-cancel">Declined</span></h6></th>
                    <th className={`${styles.currency} ${styles.columnLabel}`}><h6 className="mb-0"><span className="color-green">Accepted</span></h6></th>
                  </tr>
                  <tr>
                    {eliminations.length ? <th className={styles.categoryHeader} colSpan="6"><h6 className="mb-0"><Link to={`/recommendation/health-check/${job.healthCheckId}`}>Elimination</Link></h6></th> : null}
                  </tr>
                  {eliminations.map(sum => (
                    <tr key={sum.recommendationType.name}>
                      <td><Link to={`/recommendation/health-check/${job.healthCheckId}?recType=${[1, 2].includes(sum.recommendationType.recommendationTypeId) ? 'Unused and Excessive Lines' : recTypes[sum.recommendationType.name]?.fullName || sum.recommendationType.name}`}>{sum.recommendationType.name}</Link></td>
                      <td className={styles.currency}>{formatAsCurrency(calculateSof(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateOpen(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateNos(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateSpycareAccepted(sum, job, location))}</td>
                    </tr>
                  ))}
                  <tr>
                    {reductions.length ? <th className={styles.categoryHeader} colSpan="6"><h6 className="mb-0"><Link to={`/recommendation/health-check/${job.healthCheckId}?type=CR`}>Cost Reduction</Link></h6></th> : null}
                  </tr>
                  {reductions.map(sum => (
                    <tr key={sum.recommendationType.name}>
                      <td><Link to={`/recommendation/health-check/${job.healthCheckId}?recType=${recTypes[sum.recommendationType.name]?.fullName || sum.recommendationType.name}&type=CR`}>{sum.recommendationType.name}</Link></td>
                      <td className={styles.currency}>{formatAsCurrency(calculateSof(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateOpen(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateNos(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateSpycareAccepted(sum, job, location))}</td>
                    </tr>
                  ))}
                  <tr>
                    {others.length ? <th className={styles.categoryHeader} colSpan="6"><h6 className="mb-0">Retro / Adjustments</h6></th> : null}
                  </tr>
                  {others.map(sum => (
                    <tr key={sum.recSummaryId}>
                      <td>{sum.recommendationType.name}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateSof(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateOpen(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateNos(sum, job, location))}</td>
                      <td className={styles.currency}>{formatAsCurrency(calculateSpycareAccepted(sum, job, location))}</td>
                    </tr>
                  ))}
                  <tr className={styles.totalsRow}>
                    <td>Totals</td>
                    <td className={styles.currency}>{formatAsCurrency(sofTotal([...eliminations, ...reductions, ...others], job, location))}</td>
                    <td className={styles.currency}>{formatAsCurrency(openTotal([...eliminations, ...reductions, ...others], job, location))}</td>
                    <td className={styles.currency}>{formatAsCurrency(nosTotal([...eliminations, ...reductions, ...others], job, location))}</td>
                    <td className={styles.currency}>{formatAsCurrency(spycareAcceptedTotal([...eliminations, ...reductions, ...others], job, location))}</td>
                  </tr>
                </Table>
              </div>
            </Card>
            <Card className="">
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Key</Card.Title>
                </div>
                <Card.Text>
                  <Row>
                    <Col xs={12} lg={3}>
                      <p><span className="color-warning-alt"><strong>Open / Outstanding</strong></span>, indicates that you haven’t yet responded with a decision for these recommendations.</p>
                    </Col>
                    <Col xs={12} lg={3}>
                      <p><span className="color-cancel"><strong>Declined</strong></span>, indicates that you have decided recommendations shouldn’t be implemented.</p>
                    </Col>
                    <Col xs={12} lg={3}>
                      <p><span className="color-green"><strong>Accepted</strong></span>, indicates that SpyGlass has saved you money by implementing recommendations that you approved.</p>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default HealthCheckSummary
