import React, { useState, useEffect } from 'react';
import { useJob } from '../components/JobProvider';
import { usePortal } from '../components/PortalProvider';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import DashboardNav from '../components/DashboardNav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styles from './HealthCheckHistory.module.scss';
import { useAuth } from '../components/AuthProvider';

export default function HealthCheckHistory() {
  const [healthChecks, setHealthChecks] = useState([])
  const { spycareJobId: spycareJob } = useJob()
  const portal = usePortal()
  const { token } = useAuth()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/health_check/spycare_job/${spycareJob}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(healthChecks => {
        const sorted = healthChecks.map((hc, i) => ({
          ...hc,
          name: hc.name || `HealthCheck ${i + 1}`
        })).reverse()
        setHealthChecks(sorted)
      })
  }, [spycareJob])

  return (
    <>
      <DashboardNav />
      <Container className="mt-3">
        <h1>HealthCheck History</h1>
        {healthChecks.map(hc => (
          <Row key={hc.healthCheckId} className={styles.hcRow}>
            <Link to={`/health-check-summary/${hc.healthCheckId}`}>
              {hc.name}
            </Link>
          </Row>
        ))}
      </Container>
    </>
  )
}
