import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthProvider';
import { Modal, Form, Row, Button } from 'react-bootstrap';
import invTypeMap from '../json/inventory_types.json';

const EditHealthCheckRecommendationModal = ({ isRecModalActive, callback, jobId, inventoryId, healthCheckId }) => {
  const { token } = useAuth()
  const [recTypes, setRecTypes] = useState(null)
  const [healthChecks, setHealthChecks] = useState(null)
  const [invTypes, setInvTypes] = useState([])
  const [invOpts, setInvOpts] = useState([])
  const [recType, setRecType] = useState(null)
  const [invType, setInvType] = useState(null)

  useEffect(() => {
    if (isRecModalActive) {
      setRecType(isRecModalActive.recommendations[0].recommendationType)
      setInvType(isRecModalActive.inventoryType)
    }
  }, [isRecModalActive])

  useEffect(() => {
    if (!recType) { setInvOpts(invTypes) }
    else {
      const newOpts = invTypes.filter(i => invTypeMap[recType.name].includes(i.name))
      setInvOpts(newOpts)
      setInvType(newOpts.find(opt => opt.name === invType?.name) || newOpts[0])
    }
  }, [recType, invTypes])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/recommendation_type`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(recTypes => {
        const filteredRecTypes = recTypes.filter(type => type.category !== 'Adjustment')
        setRecTypes(filteredRecTypes)
      })

    fetch(`${process.env.REACT_APP_API_URL}/data/inventory_type`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(invTypes => {
        setInvTypes(invTypes)
      })

    fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${jobId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(job => {
        setHealthChecks(job.healthChecks)
      })
  }, [token, jobId])

  const editRecommendation = async () => {
    console.log(isRecModalActive)
    const recBody = [{ ...isRecModalActive.recommendations[0], recommendationType: recType }]
    await fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
      body: JSON.stringify(recBody),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const inventory = [{ ...isRecModalActive, inventoryType: invType }]
    await fetch(`${process.env.REACT_APP_API_URL}/data/inventory`, {
      body: JSON.stringify(inventory),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    callback(true)
  }

  return (
    <Modal show={isRecModalActive} onHide={() => callback()}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h2>Add Recommendation</h2>
      </Modal.Header>
      <div>
        <Modal.Body>
          {/* <Form>  */}
          <Form.Group className="mb-3">
            <Form.Label>Recommendation Type</Form.Label>
            <Form.Select value={recType?.recommendationTypeId || 0} onChange={(e) => {
              setRecType(recTypes.find(rt => rt.recommendationTypeId == e.target.value))
            }}>
              <option key={0} value={0} disabled></option>
              {
                recTypes
                  ?
                  recTypes.map(recType => (
                    <option key={recType.recommendationTypeId} value={recType.recommendationTypeId}>{recType.name}</option>
                  ))
                  : null
              }
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Inventory Type</Form.Label>
            <Form.Select value={invType?.inventoryTypeId} onChange={(e) => {
              setInvType(invTypes.find(it => it.inventoryTypeId == e.target.value))
            }}>
              <option key={0} value={0} disabled></option>
              {
                invOpts
                  ?
                  invOpts.map(invType => (
                    <option key={invType.inventoryTypeId} value={invType.inventoryTypeId}>{invType.name}</option>
                  ))
                  : null
              }
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="d-block mx-auto"
            variant="secondary"
            size="md"
            onClick={() => editRecommendation()}
          >
            Save Recommendation
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default EditHealthCheckRecommendationModal