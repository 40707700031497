import React, { useState, useEffect} from 'react';
import { useAuth } from '../components/AuthProvider';
import { Modal, Form, Row, Button } from 'react-bootstrap';

const AddHealthCheckRecommendationModal = ({isRecModalActive, callback, jobId, inventoryId, healthCheckId, inventories}) => {
    const { token } = useAuth()
    const [recTypes, setRecTypes] = useState(null)
    const [feedbackTypes, setFeedbackTypes] = useState(null)
    const [ipmSupportOptions, setIPMSupportOptions] = useState(null)
    const [recType, setRecType] = useState(null)
    const [healthChecks, setHealthChecks] = useState(null)
    const [taxExemptState, setTaxExemptState] = useState(null)
    const [taxExemptFederal, setTaxExemptFederal] = useState(null)

    const [newRec, setNewRec] = useState({
        feedbackTypeId: 1
    })

    useEffect(() => {
      const inv = inventories.find(i => i.inventoryId == inventoryId)
      setNewRec({
        ...newRec,
        baseRate: inv?.baseRate || 0,
        surchargeAndFees: inv?.surchargeAndFees || 0,
        stateTax: !taxExemptState ? inv?.stateTax || 0 : 0,
        federalTax: !taxExemptFederal ? inv?.federalTax || 0 : 0,
        locationName: inv?.locationName,
        inboundTest: inv?.inboundTest,
        clientDirectory: inv?.clientDirectory,
        localUsage: inv?.localUsage,
        localTollUsage: inv?.localTollUsage,
        ldUsage: inv?.ldUsage,
        localTollPic: inv?.localTollPic,
        ldPic: inv?.ldPic,
        providerPhone: inv?.providerPhone,
        billDate: inv?.billDate,
        csrDate: inv?.csrDate,
        billingName1: inv?.billingName1,
        billingName2: inv?.billingName2,
        billingAddress: inv?.billingAddress,
        billingCity: inv?.billingCity,
        billingState: inv?.billingState,
        billingZip: inv?.billingZip,
        contractedRate: inv?.contractedRate,
        contractTerms: inv?.contractTerms,
        contractExpire: inv?.contractExpire,
        contractPlanName: inv?.contractPlanName,
        onLocalBill: inv?.onLocalBill,
        wirelessName: inv?.wirelessName,
        hasFeatures: inv?.hasFeatures,
        localServiceType: inv?.localServiceType
      })
    }, [inventoryId, inventories, isRecModalActive])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/data/recommendation_type`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(recTypes => {
            const filteredRecTypes = recTypes.filter(type => type.category !== 'Adjustment')
            setRecTypes(filteredRecTypes)
            setRecType(filteredRecTypes[0])
          })

        fetch(`${process.env.REACT_APP_API_URL}/data/feedback_type`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(feedbackTypes => {
            setFeedbackTypes(feedbackTypes);
          })

        fetch(`${process.env.REACT_APP_API_URL}/data/user/authority/ROLE_IPM_Support`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(ipmSupportOptions => setIPMSupportOptions(ipmSupportOptions))

        fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${jobId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => res.json())
            .then(job => {
                setHealthChecks(job.healthChecks)
                //setForwardTerms(job.forwardTerms)
                //setRetroactiveTerms(job.retroactiveTerms)
                setTaxExemptState(job.taxExemptState)
                setTaxExemptFederal(job.taxExemptFederal)
            })
      }, [token, jobId])

    const createRecommendation = () => {
        const activeSummary = healthChecks.find((healthCheck) => {
            return healthCheck.healthCheckId == healthCheckId;
          });
        if (activeSummary === undefined) {
          const postRec = {...newRec, inventoryId: inventoryId, recType: recType}
          setNewRec(postRec)
            const newSummary = [
              {
                spycareJobId: jobId,
                recommendationTypeId: recType.recommendationTypeId,
                recommendations: [postRec],
                originalForecast: 0,
                tier3: 0,
                monthlyTrendSet: 0
              }
            ];
            fetch(`${process.env.REACT_APP_API_URL}/data/health_check`, {
                body: JSON.stringify(newSummary),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json)
                .then(result => {
                  setNewRec({ feedbackTypeId: 1 })
                  callback(true)
                },
                    (error) => {
                        console.log(error);
                    });

        } else {
            const postRec = {...newRec, healthCheckId: activeSummary.healthCheckId, inventoryId: inventoryId, recommendationType: recType}
            setNewRec(postRec)
            fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
                body: JSON.stringify([postRec]),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json)
                .then(result => {
                  setNewRec({ feedbackTypeId: 1 })
                  callback(true)
                },
                    (error) => {
                        console.log(error);
                    });
        }
    }

    return (
        <Modal show={isRecModalActive} onHide={() => callback()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header closeButton>
            <h2>Add Recommendation</h2>
          </Modal.Header>
          <div>
            <Modal.Body>
              {/* <Form>  */}
                <Form.Group className="mb-3">
                  <Form.Label>Recommendation Type</Form.Label>
                  <Form.Select value={recType?.recommendationTypeId || 0} onChange={(e) => {
                      setRecType(recTypes.find(rt => rt.recommendationTypeId == e.target.value))
                      }}>
                    <option key={0} value={0} disabled></option>
                    {
                      recTypes
                        ?
                          recTypes.map(recType => (
                            <option key={recType.recommendationTypeId} value={recType.recommendationTypeId}>{recType.name}</option>
                          ))
                        : null
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Feedback</Form.Label>
                  <Form.Select value={newRec?.feedbackTypeId} onChange={(e) => { setNewRec({...newRec, feedbackTypeId: feedbackTypes.find(ft => ft.feedbackTypeId === e.target.value).feedbackTypeId})} }  disabled>
                    {
                      feedbackTypes
                        ?
                          feedbackTypes.map(feedbackType => (
                            <option key={feedbackType.feedbackTypeId} value={feedbackType.feedbackTypeId}>{feedbackType.name}</option>
                          ))
                        : null
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Responsible</Form.Label>
                  <Form.Select value={newRec?.assignedIpm || 0} onChange={(e) => { setNewRec({...newRec, assignedIpm: ipmSupportOptions.find(ipm => ipm.username === e.target.value).username}) }}>
                    <option key={0} value={0} disabled></option>
                    {
                      ipmSupportOptions
                        ?
                          ipmSupportOptions.map(ipm => (
                            <option key={ipm.username} value={ipm.username}>{ipm.username}</option>
                          ))
                        : null
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Line Number</Form.Label>
                  <Form.Control type='text' value={newRec?.lineNumber} onChange={(e) => setNewRec({...newRec, lineNumber: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Service Type</Form.Label>
                  <Form.Control type="text" value={newRec?.myServiceType} onChange={(e) => setNewRec({...newRec, myServiceType: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Provider</Form.Label>
                  <Form.Control type="text" value={newRec?.myProvider} onChange={(e) => setNewRec({...newRec, myProvider: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Wireless Name</Form.Label>
                  <Form.Control type="text" value={newRec?.wirelessName} onChange={(e) => setNewRec({...newRec, wirelessName: e.target.value})}/>
                </Form.Group>
                <Row>
                    <Form.Group className="mb-3 col-lg-6">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control type="text" value={newRec?.accountNumber} onChange={(e) => setNewRec({...newRec, accountNumber: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6">
                        <Form.Label>Sub Account Number</Form.Label>
                        <Form.Control type="text" value={newRec?.subAccountNumber} onChange={(e) => setNewRec({...newRec, subAccountNumber: e.target.value})}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3 col-lg-6">
                      <Form.Label>Contract Terms</Form.Label>
                      <Form.Control type="text" value={newRec?.contractTerms} onChange={(e) => setNewRec({...newRec, contractTerms: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6">
                      <Form.Label>Contract Expiration</Form.Label>
                      <Form.Control type="date" value={newRec?.contractExpire} onChange={(e) => setNewRec({...newRec, contractExpire: e.target.value})}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Service Address</Form.Label>
                      <Form.Control type="text" value={newRec?.address} onChange={(e) => setNewRec({...newRec, address: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Service City</Form.Label>
                      <Form.Control type="text" value={newRec?.city} onChange={(e) => setNewRec({...newRec, city: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Service State</Form.Label>
                      <Form.Control type="text" value={newRec?.state} onChange={(e) => setNewRec({...newRec, state: e.target.value})}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Base Rate</Form.Label>
                      <Form.Control type="number" value={newRec?.baseRate} onChange={(e) => {
                          setNewRec({...newRec, baseRate: parseFloat(e.target.value)})
                        }}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Surcharges & Fees</Form.Label>
                      <Form.Control type="number" value={newRec?.surchargeAndFees} onChange={(e) => {
                        setNewRec({...newRec, surchargesAndFees: parseFloat(e.target.value)})
                        //calculateTotals()
                        }}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>State Tax</Form.Label>
                      <Form.Control type="number" value={newRec?.stateTax} onChange={(e) => {
                          setNewRec({...newRec, stateTax: parseFloat(e.target.value)})
                          //calculateTotals()
                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Federal Tax</Form.Label>
                      <Form.Control type="number" value={newRec?.federalTax} onChange={(e) => {
                          setNewRec({...newRec, federalTax: parseFloat(e.target.value)})
                          //calculateTotals()
                      }}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Proposed Base Rate</Form.Label>
                      <Form.Control type="number" pattern="^\d*(\.\d{0,2})?$" value={newRec?.proposedBaseRate} onChange={(e) => {
                          setNewRec({...newRec, proposedBaseRate: parseFloat(e.target.value)})
                          //calculateTotals()
                       }}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Proposed Surcharges and Fees</Form.Label>
                      <Form.Control type="number" value={newRec?.proposedSurchargesAndFees} onChange={(e) => {
                          setNewRec({...newRec, proposedSurchargesAndFees: parseFloat(e.target.value)})
                          //calculateTotals()
                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Proposed State Tax</Form.Label>
                      <Form.Control type="number" value={newRec?.proposedStateTax} onChange={(e) => {
                          setNewRec({...newRec, proposedStateTax: parseFloat(e.target.value)})
                          }}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                      <Form.Label>Proposed Federal Tax</Form.Label>
                      <Form.Control type="number" value={newRec?.proposedFederalTax} onChange={(e) => {
                          setNewRec({...newRec, proposedFederalTax: parseFloat(e.target.value)})
                        }}/>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Confirmation Number</Form.Label>
                  <Form.Control type="text" value={newRec?.confirmationNumber} onChange={(e) => setNewRec({...newRec, confirmationNumber: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Retro Amount</Form.Label>
                  <Form.Control type="number" value={newRec?.retroAmount} onChange={(e) => {
                      setNewRec({...newRec, retroAmount: parseFloat(e.target.value)})
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Retroactive</Form.Label>
                  <Form.Select value={newRec?.retroactive || 0} onChange={(e) => setNewRec({...newRec, retroactive: e.target.value})}>
                    <option key={0} value={0} disabled></option>
                    <option value='Not Billing Per Contract'>Not Billing Per Contract</option>
                    <option value='Previously Cancelled'>Previously Cancelled</option>
                    <option value='Tax'>Tax</option>
                    <option value='Disconnected'>Disconnected</option>
                    <option value='Other'>Other</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Effective Date</Form.Label>
                  <Form.Control type="date" value={newRec?.dateEffective} onChange={(e) => setNewRec({...newRec, dateEffective: e.target.value})}/>
                </Form.Group>
                <Row>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Location B Address</Form.Label>
                      <Form.Control type="text" value={newRec?.locbAddress} onChange={(e) => setNewRec({...newRec, locbAddress: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Location B City</Form.Label>
                      <Form.Control type="text" value={newRec?.locbCity} onChange={(e) => setNewRec({...newRec, locbCity: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Location B State</Form.Label>
                      <Form.Control type="text" value={newRec?.locbState} onChange={(e) => setNewRec({...newRec, locbState: e.target.value})}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Billing Address</Form.Label>
                      <Form.Control type="text" value={newRec?.billingAddress} onChange={(e) => setNewRec({...newRec, billingAddress: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Billing City</Form.Label>
                      <Form.Control type="text" value={newRec?.billingCity} onChange={(e) => setNewRec({...newRec, billingCity: e.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                      <Form.Label>Billing State</Form.Label>
                      <Form.Control type="text" value={newRec?.billingState} onChange={(e) => setNewRec({...newRec, billingState: e.target.value})}/>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" value={newRec?.notes} onChange={(e) => setNewRec({...newRec, notes: e.target.value})}/>
                </Form.Group>
              {/* </Form> */}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="d-block mx-auto"
                    variant="secondary"
                    size="md"
                    onClick={() => createRecommendation()}
                >
                    Save Recommendation
                </Button>
            </Modal.Footer>
          </div>
        </Modal>
    )
}

export default AddHealthCheckRecommendationModal