import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatAsCurrency, getProjectStatus, formatHealthCheckAsJob } from '../utils';
import Container from 'react-bootstrap/Container';
import styles from './Nav.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { useJob } from './JobProvider';
import { usePortal } from './PortalProvider';
import { useAuth } from './AuthProvider';
import { useSavings } from './SavingsProvider';
import { calculateSavings } from '../utils/calculate';

export default function Nav(props) {
  const portal = usePortal()
  const { setPortal } = props
  const { jobId: job, spycareJobId: spycareJob } = useJob()
  const { token, logout } = useAuth()
  const { navSavings, setNavSavings } = useSavings()

  const [projectStatus, setProjectStatus] = useState(null)
  const [clientName, setClientName] = useState(null)


  useEffect(() => {
    if (portal === 'SpyGlass') {
      if (job) {
        fetch(`${process.env.REACT_APP_API_URL}/data/job/${job}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(job => {
            const { savingsCaptured } = calculateSavings(job)
            setNavSavings(savingsCaptured)
            setProjectStatus(getProjectStatus(job.jobStatus.jobStatusId))
            setClientName(job.client?.clientName)
          })
          .catch(err => {
            setProjectStatus('materials')
          })
      }
    } else if (portal === 'SpyCare') {
      if (spycareJob) {
        fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${spycareJob}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(job => {
            const currentHealthCheck = job.healthChecks.length ? job.healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)[0] : { recommendations: [] }
            const healthCheckAsJob = formatHealthCheckAsJob(currentHealthCheck)
            const { savingsCaptured } = calculateSavings(healthCheckAsJob, 1)
            setNavSavings(savingsCaptured)
            setClientName(job.client?.clientName)
          })
      }
    }
  }, [job, spycareJob, portal])

  return (
    <div className={styles.nav}>
      <Container>
        <div className={styles.navItems}>
          <div className={styles.logoContainer}>

            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-logo" className="p-0 d-flex align-items-center">
                <div className={styles.dropdownLogo}>
                  <div className={styles.logo}>
                    {/*<a href="/">*/}
                    <img src={`/logo/${portal}.svg`} alt={portal} />
                    {/*</a>*/}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark" className="pt-0 pb-0 mt-2 logoSwitchCaret">
                <div className={styles.dropdownDescription}>Switch Portals</div>
                <Dropdown.Item className={portal === 'SpyGlass' ? '' : 'active'} onClick={() => setPortal('SpyGlass')} as={Link} to="/">
                  <div className={styles.dropdownLogoItem}>
                    <img src="/logo/SpyGlass.svg" alt="SpyGlass" />
                    {portal === 'SpyGlass' ? <span className={styles.indicator}>Active</span> : null}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className={portal === 'SpyCare' ? 'active' : ''} disabled={!spycareJob} onClick={() => setPortal('SpyCare')} as={Link} to="/">
                  <div className={styles.dropdownLogoItem}>
                    <img src="/logo/SpyCare.svg" alt="SpyCare" />
                    {!spycareJob ? <span><FontAwesomeIcon icon="lock" /></span> : null}
                    {portal === 'SpyCare' ? <span className={styles.indicator}>Active</span> : null}
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className={styles.statusContainer}>
            {portal === 'SpyGlass' ? (
              <div className={styles.projectStatus}>
                <div className={styles.label}>Project Status</div>
                <div className={styles.statusHeader}><img src={`/project-tracker/header/otis-header-project-tracker-${projectStatus}.png`} alt={projectStatus} /></div>
              </div>
            ) : null}
            <div className={styles.savingsStatus}>
              Total Savings: {formatAsCurrency(navSavings)}
            </div>
            {/*<div className={styles.notificationsContainer}>
                        <div className={styles.notificationsIcon}>
                            <FontAwesomeIcon icon="bell" />
                        </div>
                        <div className={styles.notificationsCounter}>3</div>
                    </div>*/}
            <div className={styles.companyName}>
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-user" className="p-0 d-flex align-items-center">
                  <div className={styles.dropdownUser}>
                    <div className={styles.name}>{clientName}</div>
                    <div className={styles.user}>
                      <div className={styles.userIcon}><FontAwesomeIcon icon={["far", "user-circle"]} /></div>
                      {/*<div className={styles.userActions}><FontAwesomeIcon icon="chevron-down" /></div>*/}
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu variant="dark">
                  <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}