import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import FileUploadButton from './FileUploadButton';
import DashboardNav from '../components/DashboardNav';
import BarChart from '../components/DashboardRecommendationProgress';
import ContractTrackerProgressBar from '../components/ContractTrackerProgressBar';
import { AgGridReact } from 'ag-grid-react'
import { formatTeam, formatHealthCheckAsJob, formatSpyCareTeam } from '../utils'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { calculateSavings } from '../utils/calculate';
import 'react-circular-progressbar/dist/styles.css';
import styles from './SpycareDashboard.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useJob } from '../components/JobProvider'
import { useAuth } from '../components/AuthProvider'

const SpycareDashboard = () => {
  const history = useHistory()
  const [data, setData] = useState(null)
  const [clientName, setClientName] = useState(null)
  const [resources, setResources] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const [contracts, setContracts] = useState([])
  const [nextHealthCheck, setNextHealthCheck] = useState('')
  const { spycareJobId: spycareJob } = useJob()
  const { token } = useAuth()

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    }
  }, [])

  const calculateDatePercentage = (endString, term) => {
    const end = moment(endString);
    const start = moment(end).subtract(term, 'months')
    let p = Math.round((moment().diff(start, 'days')) / (end.diff(start, 'days')) * 100)
    p = p > 100 ? p = 100 : p;
    const daysLeft = end.diff(moment(), 'days')
    return {
      percent: p,
      daysLeft: daysLeft
    };
  }

  const fileUploadButtonCallback = (files) => {
    if (spycareJob) {
      const formData = new FormData();
      formData.append("spycareJobId", spycareJob);
      for (let i = 0; i < files.length; i++) {
        formData.append("files[" + i + "]", files[i]);
        formData.append("documentNames[" + i + "]", files[i].name);
      }

      fetch(`${process.env.REACT_APP_API_URL}/data/spycare/multipart_document`, {
        body: formData,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.json())
        .then(docs => {
        },
          (error) => {
            console.log(error);
          });
    }
  }

  useEffect(() => {
    if (spycareJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(job => {
          const currentHealthCheck = job.healthChecks.length ? job.healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)[0] : { recommendations: [] }
          if (currentHealthCheck && currentHealthCheck.dateCreated) {
            if (job.dateNextHealthcheck) {
              const nextHC = moment(job.dateNextHealthcheck)
              setNextHealthCheck(nextHC.format('MMMM Do YYYY'))
            } else {
              const nextHC = moment(currentHealthCheck.dateCreated)
              nextHC.add(1, 'y')
              setNextHealthCheck(nextHC.format('MMMM Do YYYY'))
            }
          }
          const healthCheckAsJob = formatHealthCheckAsJob(currentHealthCheck)
          const { implementedRecCount, totalRecCount, savingsCaptured, savingsPossible, savingsDeclined, openOpportunity } = calculateSavings(healthCheckAsJob, 1)
          setData({
            implementedRecCount, totalRecCount, savingsCaptured, savingsPossible, savingsDeclined, openOpportunity
          })

          setClientName(job.client?.clientName)
        })

      fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/${spycareJob}/team`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(members => {
          const memberArr = formatSpyCareTeam(members)
          setTeamMembers(memberArr)
        })

      fetch(`${process.env.REACT_APP_API_URL}/data/spycare/document/job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(docs => {
          setResources(docs.filter(doc => doc.documentTypeId === 8))
        })
      fetch(`${process.env.REACT_APP_API_URL}/data/contract/spycare_job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.json())
        .then(resContracts => {
          const displayContracts = resContracts.filter((contract) => contract.topContract === 1).map((contract) => {
            const parsedTerm = contract.term.replace(/[^0-9]/g, '');
            return {
              provider: contract.provider,
              accountNumber: contract.accountNumber,
              termCompleted: parsedTerm ? calculateDatePercentage(contract.expirationDate, parsedTerm) : null,
              expirationDate: contract.expirationDate != null ? new Date(contract.expirationDate.replace(/-/g, '\/')).toLocaleDateString() : 'N/A',
              serviceCategory: contract.servicesIncluded,
            }
          })
          setContracts(displayContracts)
        })
    }
  }, [spycareJob])

  return (
    <>
      <DashboardNav />
      <Container className="mt-3">
        <Row>
          <Col xs={12} className="d-flex justify-content-between align-content-center">
            <h1 className="mb-0">{clientName} SpyCare Dashboard</h1>
            <div className="text-end">
              <FileUploadButton callback={fileUploadButtonCallback} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3 mb-3">
        <Row className={styles.cardContainerVh100}>
          <Col xs={12} lg={6}>
            <Card className={`${styles.topRow} card-h-50`}>
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Contact Us</Card.Title>
                </div>
                <div className="d-sm-flex justify-content-end align-items-center">
                  <div className={styles.projectLead}>
                    <div className={styles.pfp}>
                      <div className={styles.teamMemberPhoto} style={teamMembers[0]?.url ? { backgroundImage: `url(${teamMembers[0].url})` } : {}}></div>
                    </div>
                    <div className={styles.leadInfo}>
                      <h3 className="mb-1">{teamMembers[0]?.firstName} {teamMembers[0]?.lastName}</h3>
                      <h4 className="mb-1">{teamMembers[0]?.title}</h4>
                      {/*<div>Team</div>*/}
                      <div>{teamMembers[0]?.phone}</div>
                      <div className="color-primary"><a href={`mailto:${teamMembers[0]?.email}`}>{teamMembers[0]?.email}</a></div>
                    </div>
                  </div>
                  <div className={styles.meetingContainer}>
                    <div className={styles.meetingCalendar}>
                      <FontAwesomeIcon icon={["far", "calendar-alt"]} />
                    </div>
                    <div className={styles.nextHealthCheck}>
                      <div className={styles.bold}>
                        <h6 className="mb-1">Next HealthCheck</h6>
                      </div>
                      <div className="text-center">
                        <h6>{nextHealthCheck}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex justify-content-center m-auto text-center">
                  <div className="">
                    <Button variant="secondary" size="lg" className="mt-1 ms-1 me-1" onClick={() => { history.push('/help-desk') }}>
                      Go To Helpdesk
                    </Button>
                  </div>
                  <div className="">
                    <Button variant="secondary" size="lg" className="mt-1 ms-1 me-1" onClick={() => { if (teamMembers[0]?.meetingLink) { window.location.href = teamMembers[0].meetingLink } }}>
                      Schedule a Meeting
                    </Button>
                  </div>
                </div>

              </Card.Body>
            </Card>
            <Card className="card-h-50">
              <Card.Body className="pb-0">
                <div className="cardTitleContainer">
                  <Card.Title>Top Contracts</Card.Title>
                </div>
                <div className={styles.contractsContainer}>
                  <div className="ag-theme-alpine p-0 pb-3" style={{ height: '26vh', width: '100%', padding: '3em' }}>
                    <AgGridReact
                      masterDetail={true}
                      enableRangeSelection
                      detailRowAutoHeight={true}
                      rowData={contracts}
                      columnDefs={[
                        { field: "provider", headerName: "Carrier" },
                        { field: "accountNumber", headerName: "Acct #" },
                        { field: "serviceCategory", headerName: "Services" },
                        { field: "expirationDate", headerName: "Exp Date" },
                        {
                          field: "termCompleted",
                          cellRendererFramework: ContractTrackerProgressBar,
                          cellStyle: {
                            'display': 'flex',
                            'flex-direction': 'column',
                            'justify-content': 'center'
                          },
                          headerName: "Term Completed"
                        },
                      ]}
                      defaultColDef={defaultColDef}>
                    </AgGridReact>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer><Link to="/contract-tracker" className="card-link">View Contract Manager</Link></Card.Footer>
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Row className={`${styles.topRow} card-h-50`}>
              <Col xs={12}>
                <Card className="card-h-100">
                  <Card.Body>
                    <div className="cardTitleContainer">
                      <Card.Title>Recommendations Progress</Card.Title>
                    </div>
                    <BarChart chartData={data} />
                  </Card.Body>
                  <Card.Footer><Link to="/health-check-summary" className="card-link">View Recommendations</Link></Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row className="card-h-50">
              <Col lg={12}>
                <Card className="card-h-100">
                  <Card.Body className="pb-0">
                    <div className="cardTitleContainer">
                      <Card.Title>Resources</Card.Title>
                    </div>
                    <div className={styles.resourcesContainer}>
                      {
                        resources.map((r) => (
                          <div className={styles.resourceRow} key={r.name}>
                            <div className={styles.resource}>
                              <div>
                                {r.filetype && r.filetype.includes('pdf')
                                ? (<a href={r.url} rel="noreferrer noopener" target="_blank">{r.documentName}</a>)
                                : !r.base64
                                  ? r.fileName.charAt(0) !== '/'
                                    ? (<a href={r.fileName} rel="noreferrer noopener" target="_blank">{r.documentName}</a>)
                                    : <Link to={r.fileName}>{r.documentName}</Link>
                                  : (<Link to={r.fileName}>{r.documentName}</Link>)
                                }
                              </div>
                              <div className={styles[r.filetype && r.filetype.includes('pdf') ? 'PDF' : 'LINK']}>
                                {r.filetype && r.filetype.includes('pdf') ? 'PDF' : 'LINK'}
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      }
                    </div>
                  </Card.Body>
                  <Card.Footer><Link to="/file-manager" className="card-link">View File Manager</Link></Card.Footer>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SpycareDashboard
